import Flickity from "flickity";

const BigSlider = () => {
	const bigHeaderSlider = document.querySelector(".js-big-slider");
	if (!bigHeaderSlider) {
		return;
	}
	let flkty = new Flickity(bigHeaderSlider, {
		cellSelector: ".big-header__slider-image",
		wrapAround: false,
		prevNextButtons: false,
		pageDots: false,
		cellAlign: "left",
		freeScroll: false,
		setGallerySize: false,
		autoPlay: 6000,
	});

	document.addEventListener("DOMContentLoaded", function () {
		document
			.querySelector(".big-header__slider-image")[0]
			.classList.remove("page-loaded");
	});
};

export default BigSlider;
