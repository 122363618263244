const menuParent = document.querySelectorAll(
	".site-navigation .menu-item-has-children"
);
const siteNavigation = document.querySelector(".site-navigation");
const menuItemSub = document.querySelectorAll(
	".mobile-menu__inner .menu-item-has-children"
);
const hamburger = document.querySelector(".hamburger");
const menuOverlay = document.querySelector(".mobile-menu");
const siteBody = document.querySelector("body");
const sidebarMenuParent = document.querySelectorAll(
	".sidebar-menu__menu .page_item_has_children"
);

// sidebarMenuParent.forEach((menuParent, index) => {
//     menuParent.addEventListener('click', (e) => {
//         e.currentTarget.classList.toggle('is-open');
//     });
// });
//
// menuParent.forEach((menuItem, index) => {
//     menuItem.addEventListener('click', (e) => {
//         menuParent.forEach((menuItem) => {
//           if (menuItem != e.currentTarget) {
//             menuItem.classList.remove('is-open');
//           }
//         });
//
//         if (e.currentTarget.classList.contains('is-open')) {
//           siteNavigation.classList.remove('is-open');
//         } else {
//           siteNavigation.classList.add('is-open');
//         }
//
//         e.currentTarget.classList.toggle('is-open');
//     });
// });

[...menuItemSub, ...sidebarMenuParent].forEach((menuItem, index) => {
	const subButton = '<span class="sub-button"></span>';
	menuItem.insertAdjacentHTML("afterbegin", subButton);
});

const subMenuButton = document.querySelectorAll(".sub-button");
subMenuButton.forEach((button, index) => {
	setTimeout(() => {
		button.parentNode.querySelector(".sub-menu").style.pointerEvents =
			"auto";
	}, 500);

	button.addEventListener("click", (e) => {
		let subMenu = button.parentNode.querySelector(".sub-menu");

		if (subMenu == undefined) {
			subMenu = button.parentNode.querySelector(".children");
		}

		button.classList.toggle("clicked");
		subMenu.classList.toggle("is-open");
	});
});

hamburger.addEventListener("click", function () {
	hamburger.classList.toggle("is-active");
	menuOverlay.classList.toggle("is-open");
	siteBody.classList.toggle("no-scroll");
});
