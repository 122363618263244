// Polyfills
import "@babel/polyfill";
import "./polyfills.js";
import "classlist-polyfill";

// General
import $ from "jquery";
import LazyLoad from "vanilla-lazyload";
// Components
import "./components/SiteNavigation.js";
import "./components/PopupHandler.js";
import "./components/DisclaimerHandler.js";
import ArticleSlider from "./components/ArticleSlider.js";
import BigSlider from "./components/BigSlider.js";
import CisionHandler from "./components/CisionHandler.js";
import ReleaseHandler from "./components/ReleaseHandler.js";

document.addEventListener("DOMContentLoaded", function () {
	const lazyLoad = new LazyLoad({
		elements_selector: ".lazy",
	});

	CisionHandler();
	ArticleSlider();
	BigSlider();
	ReleaseHandler();
});
