import Flickity from 'flickity';

const ArticleSlider = () => {

    const sliderElement = document.querySelectorAll('.js-slider-block');
    const sliderColumn = document.querySelectorAll('.slider-block__slide');

    if ( sliderElement.length != 0 ) {
        for ( let i = 0; i < sliderElement.length; i++ ) {
            let flkty = new Flickity( sliderElement[i], {
                cellSelector: '.slider-block__slide',
                wrapAround: false,
                prevNextButtons: false,
                pageDots: false,
                cellAlign: 'left',
                freeScroll: false,
                setGallerySize: false
            });

            window.addEventListener( 'load', () => {
                flkty.resize();
                sliderColumn[0].classList.add('full-opacity');
                sliderColumn[1].classList.add('full-opacity');
            });

            const sliderColumnTransition = (i) =>
            {
                let prevColumn = sliderColumn[i - 1];
                let currColumn = sliderColumn[i];
                let nextColumn = sliderColumn[i + 1];
                let currentIndex = i;

                if (prevColumn != undefined) {
                    prevColumn.classList.add('prev-column');
                }

                sliderColumn.forEach((col, i) => {
                    if (col != currColumn && col != nextColumn && col != prevColumn) {
                        col.classList.remove('prev-column');
                    }
                    //
                    // if (i < currentIndex) {
                    //     col[]
                    // }

                    if (col != currColumn && col != nextColumn) {
                        col.classList.remove('full-opacity');
                    }
                });

                currColumn.classList.remove('prev-column');
                currColumn.classList.add('full-opacity');
                nextColumn.classList.add('full-opacity');
            }

            flkty.on('change', (i) => { sliderColumnTransition(i) });
        }
    }
}

export default ArticleSlider;
