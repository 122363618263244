import $ from "jquery";

function CisionHandler() {
	if (document.querySelector("#templateTicker")) {
		cision.websolution.tickers.render();
	}

	if (document.querySelector("#target-sharegraph-wrapper")) {
		cision.websolution.sharegraph.init();
	}

	if (document.querySelector("#target-trades")) {
		cision.websolution.trades.render();
	}

	if (document.querySelector("#target-share-performance")) {
		cision.websolution.performance.render();
	}

	if (document.querySelector("#target-orderbook")) {
		cision.websolution.orderbook.render();
	}

	// if (document.querySelector('#target-release-list')) {
	//     $(function () {
	//         changePage(0, 10);
	//     });
	//
	//     function changePage(pageIndex, pageSize) {
	//         cision.websolution.newsfeed.render({
	//             pageIndex: pageIndex,
	//             pageSize: pageSize,
	//             introMaxLength: 120
	//         });
	//     };
	// }

	$(function () {
		if (typeof cision === "undefined") return;

		var settings = cision.websolution.settings.general,
			texts = cision.websolution.texts[settings.uiLanguage];

		$(
			"<li class='nav-item'><a href='#target-largest' class='nav-link active' data-toggle='tab'>" +
				texts.TextLargest +
				"</a></li>"
		).appendTo("#ownershipTabs");
		$(
			"<li class='nav-item'><a href='#target-grouped' class='nav-link' data-toggle='tab'>" +
				texts.TextGrouped +
				"</a></li>"
		).appendTo("#ownershipTabs");
		$(
			"<li class='nav-item'><a href='#target-sharesizegroups' class='nav-link' data-toggle='tab'>" +
				texts.TextRange +
				"</a></li>"
		).appendTo("#ownershipTabs");
		$(
			"<li class='nav-item'><a href='#target-area' class='nav-link' data-toggle='tab'>" +
				texts.TextDist +
				"</a></li>"
		).appendTo("#ownershipTabs");

		$("#ownershipTabs a").click(function (e) {
			e.preventDefault();
			cision.websolution.ownership.renderOwnershipTab($(this)[0].hash);
			$(this).tab("show");
		});

		//render first ownership
		cision.websolution.ownership.renderLargestShareholders();
		settings.ownershipTabLoaded["#target-largest"] = true;
		$("#ownershipTabs a:first").tab("show");
	});

	if (document.querySelector("#target-release-detail")) {
		$(function () {
			// Retrieve encrypted ID
			var releaseIdentifier = queryObj()["releaseIdentifier"];
			var currentPageUrl = window.location.href;

			cision.websolution.newsfeed.renderDetail({
				releaseIdentifier: releaseIdentifier,
				currentPageUrl: currentPageUrl,
			});
		});

		function queryObj() {
			var result = {};
			var queryString = location.search.slice(1);
			var re = /([^&=]+)=([^&]*)/g;
			var m;

			while ((m = re.exec(queryString))) {
				result[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
			}

			return result;
		}
	}

	if (document.querySelector(".subscribe-form")) {
		$(function () {
			$(".subscribe-form__result").hide();
			$("#sendForm").on("click", function (e) {
				e.preventDefault();
				var url = $("form").attr("action"),
					formData = $(".subscribe-form__form").serializeArray(),
					recaptchaResponse = grecaptcha.getResponse();
				if (recaptchaResponse) {
					$.ajax({
						type: "POST",
						url: url,
						data: formData,
					})
						.done(function () {
							if (CURRENT_LANG_CODE === "en") {
								$(".subscribe-form__result")
									.text(
										"Your subscription is now saved and you will recive an email to verify your email address and activate the subscription."
									)
									.fadeIn(300);
							} else {
								$(".subscribe-form__result")
									.text(
										"Din prenumeration är nu sparad och du kommer strax att få ett bekräftelsemail."
									)
									.fadeIn(300);
							}

							$(".mail input, .mail select").val("");

							grecaptcha.reset();

							$.each(
								$(".subscribeInfo input"),
								function (ix, item) {
									$(item).attr("checked", false);
								}
							);
						})
						.fail(function (e) {
							$(".subscribe-form__result").addClass("failed");

							if (CURRENT_LANG_CODE === "en") {
								$(".subscribe-form__result")
									.text(
										"Something went wrong. Please try again."
									)
									.fadeIn(300);
							} else {
								$(".subscribe-form__result")
									.text(
										"Nånting gick fel. Var god försök igen."
									)
									.fadeIn(300);
							}
							grecaptcha.reset();
						});
				}
			});
		});
	}
}

export default CisionHandler;
