const approveButton = document.querySelector(".js-approve");
const cancelButton = document.querySelector(".js-cancel");
const approveButtonSecond = document.querySelector(".js-approve-second");
const cancelButtonSecond = document.querySelector(".js-cancel-second");
const disclaimerFirstView = document.querySelector(".disclaimer__first");
const disclaimerSecondView = document.querySelector(".disclaimer__second");
const disclaimerThirdView = document.querySelector(".disclaimer__third");
const radioButtons = Array.from(document.querySelectorAll('.js-check'));

// SORRY THIS IS REAL BAD!!!

if (cancelButton) {
    cancelButton.addEventListener('click', (e) => {
        window.location.replace(HOME_URL);
    });
}

if (cancelButtonSecond) {
    cancelButtonSecond.addEventListener('click', (e) => {
        window.location.replace(HOME_URL);
    });
}

if (approveButton) {
    approveButton.addEventListener('click', (e) => {
        const fromCountry = document.querySelector('.js-lives-in');
        const currentlyInCountry = document.querySelector('.js-is-in');
        const currentlyCountryValue = currentlyInCountry.options[currentlyInCountry.selectedIndex].getAttribute("data-id");
        const fromCountryValue = fromCountry.options[fromCountry.selectedIndex].getAttribute("data-id");

        if (fromCountryValue == 1 && currentlyCountryValue == 1) {
            disclaimerFirstView.style.display = "none";
            disclaimerSecondView.style.display = "block";
        }
        else {
            disclaimerFirstView.style.display = "none";
            disclaimerThirdView.style.display = "block";
        }
    });
}

if (approveButtonSecond) {
    approveButtonSecond.addEventListener('click', (e) => {
        const firstGroup = document.querySelector('input[name="group1"]:checked').value;
        const secondGroup = document.querySelector('input[name="group2"]:checked').value;

        if (firstGroup == 1 && secondGroup == 1) {
            disclaimerSecondView.style.display = "none";
            document.querySelector('.disclaimer').style.display = "none";
            document.querySelector('.site-holder').style.opacity = "1";
            document.querySelector('body').style = "";
        }
        else {
            disclaimerSecondView.style.display = "none";
            disclaimerThirdView.style.display = "block";
        }
    });
}
