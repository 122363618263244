const popups = document.querySelectorAll('.js-popup');
const popupOpenButton = document.querySelectorAll('.js-popup-open');
const popupCloseButton = document.querySelectorAll('.js-popup-close');

popupOpenButton.forEach(button => {
    button.addEventListener('click', (e) => {
        const _this = e.currentTarget;
        const thisTarget = _this.getAttribute('data-target');
        const targetPopup = document.querySelector(`.js-popup[data-id=${thisTarget}]`);
        targetPopup.classList.add('open');
    });
});

popupCloseButton.forEach(button => {
    button.addEventListener('click', (e) => {
        console.log(23);
        popups.forEach(popup => {
            popup.classList.remove('open');
        });
    });
});
